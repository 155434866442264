import * as React from "react";
import * as ReactDOM from "react-dom";
import * as _ from "underscore";

export interface ISelect2Option {
  id: string|number;
  text: string;
  term?: string;
}

// See defaults in (search: `rg "plugin defaults"`)
//   web_app/js/libraries/vendor/select2-3.5.2.custom-no-autocomplete.js
//   3424:    // plugin defaults, accessible to users
export interface ISelect2ComponentProps {
  width?: string;
  loadMorePadding?: number;
  closeOnSelect?: boolean;
  openOnEnter?: boolean;
  containerCss?: object;
  dropdownCss?: object;
  containerCssClass?: string;
  dropdownCssClass?: string;
  formatResult?: (result: any, container: any, query: string, escapeMarkup: any) => string;
  transformVal?: (val: string) => string;
  formatSelection?: (data: any, container: any, escapeMarkup: any) => string | undefined;
  sortResults?: (results: { text: string }[], container: any, query: { term: string }) => any;
  formatResultCssClass?: (data: any) => string;
  formatSelectionCssClass?: (data: any, container: any) => any | undefined;
  minimumResultsForSearch?: number;
  minimumInputLength?: number;
  maximumInputLength?: number;
  maximumSelectionSize?: number;
  id?: (e: any) => string;
  text?: (e: any) => string;
  matcher?: (term: any, text: string) => boolean;
  separator?: string;
  tokenSeparators?: any;
  tokenizer?: any;
  escapeMarkup?: any;
  blurOnChange?: boolean;
  selectOnBlur?: boolean;
  adaptContainerCssClass?: (c: any) => any;
  adaptDropdownCssClass?: (c: any) => any;
  nextSearchTerm?: (selectedObject: any, currentSearchTerm: any) => any;
  searchInputPlaceholder?: string;
  createSearchChoicePosition?: string;
  shouldFocusInput?: (instance: any) => boolean;

  // Locales
  placeholder: string;
  formatMatches?: (matches: any) => string;
  formatNoMatches?: () => string;
  formatAjaxError?: (jqXHR: any, textStatus: any, errorThrown: any) => string;
  formatInputTooShort?: (input: string, min: number) => string;
  formatInputTooLong?: (input: string, max: number) => string;
  formatSelectionTooBig?: (limit: number) => string;
  formatLoadMore?: (pageNumber: number) => string;
  formatSearching?: () => string;
  query?: (query: { term: string, callback: (results: Array<ISelect2Option>) => void }) => void;
  initSelection?: (element: any, callback: any) => void;
  multiple: boolean;
  select2Class?: string; // "abstract", "single", "multi"
  select2Id: string;
  preselectedItems?: Array<ISelect2Option>;

  // Callbacks
  selectionCallback?: (e: any) => void;
}

class Select2Component extends React.Component<ISelect2ComponentProps, {}> {
  private select2Component: HTMLInputElement | null;

  constructor(props: ISelect2ComponentProps) {
    super(props);
    this.select2Component = null;
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  render() {
    return (
      <form>
        <input type="text"
               id={this.props.select2Id}
               ref={(e) => { this.select2Component = e; }}
               className="input-group__item-action js-select2-single2"
               defaultValue=""
               data-placeholder={this.props.placeholder} />
      </form>
    );
  }

  componentDidMount() {
    (document as any).addEventListener("mousedown", this.handleClickOutside);
    let select2Options = {
      containerCssClass: "custom-select2",
      width: "100%",
      multiple: this.props.multiple,
      formatResult: (item: ISelect2Option) => {
        return `<span value=${item.id}>${item.text}</span>`;
      },
      formatSelection: (item: ISelect2Option) => {
        const itemText = item.term || item.text;
        return `<span value=${item.id}>${itemText}</span>`;
      },
      query: this.props.query,
    };
    const select2Select = jQuery(this.select2Component as any);

    if (this.props.sortResults) {
      select2Options = this.extendOptions(
        select2Options,
        { sortResults: this.props.sortResults }
      );
    }

    if (this.props.formatNoMatches) {
      select2Options = this.extendOptions(
        select2Options,
        { formatNoMatches: this.props.formatNoMatches }
      );
    }

    if (this.props.formatSearching) {
      select2Options = this.extendOptions(
        select2Options,
        { formatSearching: this.props.formatSearching }
      );
    }

    if (this.props.maximumSelectionSize) {
      select2Options = this.extendOptions(
        select2Options,
        {
          maximumSelectionSize: this.props.maximumSelectionSize,
          formatSelectionTooBig: this.props.formatSelectionTooBig
        }
      );
    }

    if (this.props.minimumInputLength) {
      select2Options = this.extendOptions(
        select2Options,
        {
          minimumInputLength: this.props.minimumInputLength,
          formatInputTooShort: this.props.formatInputTooShort
        }
      );
    }

    (select2Select as any).select2(select2Options);

    if (this.props.preselectedItems) {
      // For this to work, formatResult and formatSelection needs to be defined.
      (select2Select as any).select2("data", this.preselectedItems());
    }

    select2Select.change((e: any) => {
      if (this.props.selectionCallback) {
        this.props.selectionCallback(parseInt(e.val, 10));
      }
    });
    select2Select.focus();
  }

  private preselectedItems() {
    if (this.props.preselectedItems) {
      if (this.props.multiple) {
        return this.props.preselectedItems;
      } else {
        return this.props.preselectedItems[0];
      }
    } else {
      return [];
    }
  }

  componentWillUnmount() {
    const select2 = jQuery(this.select2Component as any);
    (document as any).addEventListener("mousedown", this.handleClickOutside);
    (select2 as any).select2("destroy");
  }

  private extendOptions(options: any, additions: any) {
    return _.extend({}, options, additions);
  }

  private handleClickOutside(event: any) {
    const component = this.select2Component;
    if (component && !component.contains(event.target)) {
      const select2 = jQuery(this.select2Component as any);
      (select2 as any).select2("close");
    }
  }
}

export default Select2Component;
