import Marionette from 'backbone.marionette';
import * as React from 'react'
import ReactWrapper from 'views/marionetteReactWrapper'
import SortableButtonList from 'components/sortableButtonList';
import template from 'text-loader!templates/newsroom/readerAdminEditView.html';
import ReaderTokenUrlView from 'views/newsroom/readerTokenUrlView';
import moment from 'moment';
import _ from 'underscore';
import I18n from 'i18n';
import DateFormatter from 'libraries/dateFormatter';
import session from 'models/sessionInstance';
import users from 'collections/usersInstance'

class ReaderAdminEditView extends Marionette.Layout {
    initialize() {
        this.readerTokenUrlView = new ReaderTokenUrlView({model: this.model});
    }

    serializeData() {
        let config               = this.model;
        let archivePattern       = this._getArchivePattern();
        let tokenRefreshPattern  = this._getTokenRefreshPattern();
        let emailSettings        = this._getEmailSettings();
        let statisticsUrl        = this._getStatisticsUrl();
        let readerHandoutPdfUrl  = this._getReaderHandoutPdfUrl();
        let serializedData       = config.toJSON();
        let nextRefresh          = config.get('next_token_refresh');
        let nextUpdateDate;
        let nextUpdateTime;

        if (nextRefresh) {
            nextUpdateDate = moment.unix(nextRefresh).format('DD.MM.YYYY');
            nextUpdateTime = moment.unix(nextRefresh).format('HH:mm');
        }

        const date = this.fetchCreationDate();
        const fullName = this.fetchCreatorName();
        const metaInformationKey = this.model.get("shared") ?
          "webapp.reader_admin.edit.meta_information.public" :
          "webapp.reader_admin.edit.meta_information.private"
        const readerMetaInformation = I18n.t(
            metaInformationKey,
            {
                date: date,
                fullname: fullName
            }
        )
        let data = _.extend(
            serializedData,
            {
                isOneTimeReader: config.get('one_time_reader'),
                isAdmin: session.isGroupAdmin(),
                nextUpdateDate: nextUpdateDate,
                nextUpdateTime: nextUpdateTime,
                archivePattern: archivePattern,
                notificationConfigurations: this.model.get('notification_configurations'),
                emailSettings: emailSettings,
                statisticsUrl: statisticsUrl,
                readerHandoutPdfUrl: readerHandoutPdfUrl,
                tokenRefreshPattern: tokenRefreshPattern,
                isOldContract: session.contractVersion() < 3,
                useNewReaderManagement: session.hasFeature('use_new_reader_management'),
                readerMetaInformation: readerMetaInformation
            }
        );

        return data;
    }

    fetchCreationDate() {
        let rawDate;
        if (this.model.get("created_at") > 0) {
            rawDate = moment(this.model.get("created_at") * 1000);
        }
        else {
            rawDate = new Date();
        }
        return DateFormatter.formatDate(rawDate);
    }

    fetchCreatorName() {
        let userId = this.model.get('creator_id')
        let fullName;
        if (userId && users.get(userId)) {
            fullName = users.get(userId).get("full_name");
        }
        else if (userId) {
            fullName = I18n.t("webapp.agents.edit.settings.deleted_user");
        }
        else {
            fullName = users.currentUser.get('full_name');
        };
        return fullName;
    }

    showCategories(props) {
        // Sometimes the layout is not yet rendered, so we skip this in that
        // case
        if (this.categories) {
            const sortableButtonList = new ReactWrapper({
              getComponent: () => {
                return <SortableButtonList {...props}/>
              }
            });
            this.categories.show(sortableButtonList);
        }
    }

    showNotificationConfigurations(props) {
        // Sometimes the layout is not yet rendered, so we skip this in that
        // case
        if (this.notificationConfigurations) {
            const sortableButtonList = new ReactWrapper({
              getComponent: () => {
                return <SortableButtonList {...props}/>
              }
            });
            this.notificationConfigurations.show(sortableButtonList);
        }
    }

    showEditableTitle(view) {
        this.editableTitle.show(view);
    }

    onRender() {
        this._initializeModal();
        this._registerListeners();
        this._showReaderTokenUrl(this.readerTokenUrlView);
    }

    _showReaderTokenUrl(view) {
        this.readerTokenUrl.show(view);
    }

    _getArchivePattern() {
        let nextIssue = I18n.t('webapp.reader_admin.edit.issue_pattern_description.next_issue',
                { date: this._formattedNextIssueDate() }
        );
        let timeMoment =
            moment(this.model.get('archive_date_schedule').times[0], 'h:mm');
        let time = DateFormatter.formatTime(timeMoment);
        let timesPerWeek = this.model.get('archive_date_schedule').weekdays.length;
        let buttonText =
            I18n.t('webapp.reader_admin.edit.issue_pattern_description.weekdays',
                   { time: time, times_per_week: timesPerWeek }
                  );
        let archivePattern = {
            buttonText: buttonText,
            nextIssue: nextIssue
        };
        return archivePattern;
    }

    _getTokenRefreshPattern() {
        let nextToken = this._formattedNextTokenDate();
        let buttonText = '';
        switch (this.model.get('token_refresh_pattern')) {
            case 'weekdays': {
                let time = this.model.get('token_refresh_schedule').times[0];
                let timesPerWeek = this.model.get('token_refresh_schedule').weekdays.length;
                buttonText = I18n.t('webapp.reader_admin.edit.token_refresh_pattern_description.weekdays',
                        { time: time, times_per_week: timesPerWeek }
                );
                break;
            }
            case 'monthly':
                buttonText = I18n.t('webapp.reader_admin.edit.token_refresh_pattern_description.monthly');
                break;
            case 'quarterly':
                buttonText = I18n.t('webapp.reader_admin.edit.token_refresh_pattern_description.quarterly');
                break;
            case 'same as archive':
                buttonText = I18n.t('webapp.reader_admin.edit.token_refresh_pattern_description.same_as_archive');
                break;
            default:
                nextToken = '';
                buttonText = I18n.t('webapp.reader_admin.edit.token_refresh_pattern_description.none');
        }
        let tokenRefreshPattern = {
            buttonText: buttonText,
            nextToken: nextToken
        };
        return tokenRefreshPattern;
    }

    _getEmailSettings() {
        let emailSettings = '';
        if (this.model.get('send_changed_token')) {
            emailSettings = I18n.t('webapp.reader_admin.edit.email_settings_emails_enabled');
        } else {
            emailSettings = I18n.t('webapp.reader_admin.edit.email_settings_emails_disabled');
        }

        return emailSettings;
    }

    _registerListeners() {
        this.ui.deleteReader.on('click', () => {
            this._showDeletionModal();

        });

        this.listenTo(this.model, 'changed:title', () => {
            this.model.save();
        });

        this.ui.showPresentationOptions.on('click', () => {
            this.trigger('showPresentationOptions');
        });

        this.listenTo(this.model, 'change:send_changed_token', this._emailSettingsHasChanged);
        this.listenTo(
                this.model,
                'change:archive_pattern change:archive_date_schedule change:next_archive_date_delivery_time',
                this._updateArchivePatternButton
        );
        this.listenTo(
                this.model,
                'change:token_refresh_pattern change:token_refresh_schedule change:next_token_refresh',
                this._updateTokenPatternButton
        );
    }

    _emailSettingsHasChanged() {
        let text = this._getEmailSettings();
        this.ui.emailSettingsButton.text(text);
    }

    _updateArchivePatternButton() {
        let archivePattern = this._getArchivePattern();
        let buttonText = archivePattern.buttonText;
        let nextIssue = archivePattern.nextIssue;
        this.ui.archivePatternText.html(buttonText);
        this.ui.nextIssueDateSpan.html(nextIssue);
    }

    _updateTokenPatternButton() {
        let tokenRefreshPattern = this._getTokenRefreshPattern();
        let buttonText = tokenRefreshPattern.buttonText;
        let nextToken = tokenRefreshPattern.nextToken;
        this.ui.tokenRefreshPatternText.html(buttonText);
        this.ui.nextTokenDateSpan.html(nextToken);
    }

    _initializeModal() {
        this.ui.modalDeleteButton.on('click', () => {
            this.modal.close();
            this.trigger('configuration:delete');
        });
        this.ui.modalDeleteButton.on('keydown', (e) => {
            if (e.key === "Enter") {
                this.modal.close();
                this.trigger('configuration:delete');
            }
        });
    }

    _showDeletionModal() {
        this.modal = this.ui.modalElement.bPopup();
        this.ui.modalDeleteButton.focus();
    }

    _formattedNextIssueDate() {
        let nextDeliveryTime = this.model.get(
            'next_archive_date_delivery_time'
        );
        let nextIssueDate = moment.unix(nextDeliveryTime).format('dd L');
        return nextIssueDate;
    }

    _formattedNextTokenDate() {
        let nextTokenRefresh = this.model.get(
            'next_token_refresh'
        );
        let nextTokenDate = moment.unix(nextTokenRefresh).format('dd L LT');
        return nextTokenDate;
    }

    _getStatisticsUrl() {
        return '/api/v3/reader_statistics/' + this.model.get('id') + '.csv';
    }

    _getReaderHandoutPdfUrl() {
        return '/api/v3/reader_configurations/' +
            this.model.get('id') + '/latest_handout';
    }

    onClose() {
        this.ui.modalDeleteButton.off('click');
        this.ui.modalElement.remove();
    }

    setShowPresentationOptions(value) {
        if (value) {
            this.$(this.ui.showPresentationOptions).addClass('is-active');
        } else {
            this.$(this.ui.showPresentationOptions).removeClass('is-active');
        }
    }

    toggleArchivePatternButton(value) {
        this.$(this.ui.archivePatternButton).toggleClass('is-active', value);
    }

    toggleRefreshPatternButton(value) {
        this.$(this.ui.tokenRefreshPatternButton).toggleClass('is-active', value);
    }

    toggleNotificationPatternButton(value) {
        this.$(this.ui.notificationPatternButton).toggleClass('is-active', value);
    }

    toggleEmailSettingsButton(value) {
        this.$(this.ui.emailSettingsButton).toggleClass('is-active', value);
    }

    toggleAudienceButton(value) {
        this.$(this.ui.audienceButton).toggleClass('is-active', value);
    }

    toggleAudiencePrivacyButton(value) {
        this.$(this.ui.audiencePrivacyButton).toggleClass('is-active', value);
    }

    showSendTokenModal(view) {
        this.sendTokenModalContainer.show(view);
    }

}

ReaderAdminEditView.prototype.template = template;
ReaderAdminEditView.prototype.regions = {
    editableTitle: '[data-editable-title]',
    categories: '[data-reader-categories]',
    notificationConfigurations: '[data-reader-notification-configurations]',
    sendTokenModalContainer: '[data-send-token-modal-container]',
    readerTokenUrl: '[data-reader-token-url]'
};
ReaderAdminEditView.prototype.ui = {
    sendToken: '[data-send-token-btn]',
    deleteReader: '[data-delete-reader]',
    modalElement: '[data-confirm-delete-modal]',
    modalDeleteButton: '[data-delete-delete]',
    header: '[data-editable-title]',
    showPresentationOptions: '[data-show-presentation-options-button]',
    archivePatternButton: '[data-archive-pattern-button]',
    tokenRefreshPatternButton: '[data-token-refresh-pattern-button]',
    archivePatternText: '[data-archive-pattern-text]',
    tokenRefreshPatternText: '[data-token-refresh-pattern-text]',
    nextIssueDateSpan: '[data-next-issue-date]',
    nextTokenDateSpan: '[data-next-token-date]',
    readerTokenUrl: '[data-reader-token-url]',
    emailSettingsButton: '[data-email-settings-button]',
    audienceButton: '[data-audience]',
    audiencePrivacyButton: '[data-audience-privacy]'
};
ReaderAdminEditView.prototype.triggers = {
    'click @ui.sendToken': 'sendTokenModal',
    'click @ui.archivePatternButton': 'click:archivePattern',
    'click @ui.tokenRefreshPatternButton': 'click:refreshPattern',
    'click @ui.emailSettingsButton': 'click:emailSettings',
    'click @ui.audienceButton': 'click:audience',
    'click @ui.audiencePrivacyButton': 'click:audiencePrivacy'
};
ReaderAdminEditView.prototype.events = {
    'click @ui.readerTokenUrl': '_selectTokenUrl'
};

export default ReaderAdminEditView;
